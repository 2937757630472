import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

import { MenuIcon } from 'components/common/assets/svg/svg.component.js';
import WhatIsQwerteeModal from 'components/help/WhatIsQwerteeModal';
import routeNames from 'router/routeNames.json';
import { Container } from 'components/common/Container';
import { useFeatureFlags } from 'api/useFeatureFlags';

import './Header.css';

const initialState = { vote: false, win: false, help: false };
const sweaters = process.env.REACT_APP_MENU_SWEATER === 'true';
const menuLinksClasses =
  'text-white hover:text-qw-gray-400 active:text-qw-gray-400 font-bold text-xl uppercase font-sans ml-[0.8rem]';
const menuLinksClassesMobile = `${menuLinksClasses} mb-2`;

const New = () => (
  <sub className="text-qw-yellow-200 text-xs normal-case ml-1">
    <Trans>New</Trans>
  </sub>
);

export default function Header() {
  const [submenus, setSubmenus] = useState(initialState);
  const [showDropdown, setShowDropdown] = useState(false);
  const trustpilotRef = useRef();
  const { data: featureFlags } = useFeatureFlags();

  const showSubmenu = (key) => {
    setSubmenus({ ...initialState, [key]: true });
  };

  const hideSubmenu = (key) => {
    setTimeout(() => {
      setSubmenus((oldValue) => ({ ...oldValue, [key]: false }));
    }, 300);
  };

  const showMobileMenu = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    window?.Trustpilot?.loadFromElement(trustpilotRef.current);
  }, []);

  return (
    <div className="bg-black relative">
      <Container className="flex justify-between">
        <div className="py-2">
          <h2>
            <Link
              className="block bg-qwertee-logo-mobile bg-[left_-59px] sm:bg-left-top w-[200px] md:bg-qwertee-logo md:hover:bg-qwertee-logo-hover bg-no-repeat md:bg-center text-transparent h-[42px] sm:h-[59px] sm:w-[280px] md:h-14 md:w-60"
              to={routeNames.home}
            >
              Qwertee
            </Link>
          </h2>
          <div
            ref={trustpilotRef}
            className="trustpilot-widget mt-1 -ml-6"
            data-locale="en-US"
            data-template-id="5419b637fa0340045cd0c936"
            data-businessunit-id="4de531bb00006400050fcd09"
            data-style-height="20px"
            data-style-width="100%"
            data-theme="dark"
          >
            <a
              href="https://www.trustpilot.com/review/qwertee.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>
        </div>
        <div>
          <div className="hidden md:block pt-5 text-end pr-2 text-lg">
            <Link to={routeNames.home} className={menuLinksClasses}>
              <Trans>Today's Tees</Trans>
            </Link>
            <Link to={routeNames.shopTees} className={menuLinksClasses}>
              <Trans>Tee Shop</Trans>
            </Link>
            {featureFlags.kidsTees ? (
              <Link to={routeNames.shopKids} className={menuLinksClasses}>
                <Trans>Kids Tees </Trans>
                <New />
              </Link>
            ) : (
              <Link to={routeNames.shopStickers} className={menuLinksClasses}>
                <Trans>Stickers </Trans>
                <New />
              </Link>
            )}
            {sweaters && (
              <Link to={routeNames.shopSweaters} className={menuLinksClasses}>
                <Trans>Sweaters</Trans>
              </Link>
            )}
            {!featureFlags?.hidePrintShop && (
              <Link
                to={routeNames.shopPrints}
                replace
                className={menuLinksClasses}
              >
                {featureFlags.framedPrints ? (
                  <>
                    <Trans>Framed Prints</Trans>
                    <New />
                  </>
                ) : (
                  <Trans>Prints</Trans>
                )}
              </Link>
            )}
            <Link
              to={routeNames.designsVote}
              onMouseEnter={() => showSubmenu('vote')}
              onMouseLeave={() => hideSubmenu('vote')}
              className={menuLinksClasses}
            >
              <Trans>Vote</Trans>
            </Link>
            <Link
              to={routeNames.freeTees}
              onMouseEnter={() => showSubmenu('win')}
              onMouseLeave={() => hideSubmenu('win')}
              className={menuLinksClasses}
            >
              <Trans>Win/Free</Trans>
            </Link>
            <Link
              to={routeNames.help}
              onMouseEnter={() => showSubmenu('help')}
              onMouseLeave={() => hideSubmenu('help')}
              className={menuLinksClasses}
            >
              <Trans>Help</Trans>
            </Link>
          </div>
          <div id="submenu">
            <ul
              className={classNames({
                active: submenus.vote,
              })}
            >
              <li>
                <Link
                  to={routeNames.designsQuickSubmit}
                  className={menuLinksClasses}
                >
                  <Trans>Quick Submit</Trans>
                </Link>
              </li>
              <li>
                <Link
                  to={routeNames.designsSubmit}
                  className={menuLinksClasses}
                >
                  <Trans>Submit Design</Trans>
                </Link>
              </li>
              <li>
                <Link to={routeNames.designsVote} className={menuLinksClasses}>
                  <Trans>Vote for Tees</Trans>
                </Link>
              </li>
            </ul>
            <ul
              className={classNames({
                active: submenus.win,
              })}
            >
              <li>
                <Link to={routeNames.loyaltee} className={menuLinksClasses}>
                  <Trans>Loyaltee Card</Trans>
                </Link>
              </li>
              <li>
                <Link to={routeNames.referral} className={menuLinksClasses}>
                  <Trans>Teeferral</Trans>
                </Link>
              </li>
              <li>
                <Link to={routeNames.freeTees} className={menuLinksClasses}>
                  <Trans>Win Free Tees</Trans>
                </Link>
              </li>
            </ul>
            <ul
              className={classNames({
                active: submenus.help,
              })}
            >
              <li>
                <Link to={routeNames.helpContact} className={menuLinksClasses}>
                  <Trans>Contact Us</Trans>
                </Link>
              </li>
              <li>
                <Link to={routeNames.help} className={menuLinksClasses}>
                  <Trans>Help &amp; FAQ</Trans>
                </Link>
              </li>
              <li>
                <WhatIsQwerteeModal
                  linkClassName={`${menuLinksClasses} cursor-pointer`}
                >
                  <Trans>What is Qwertee?</Trans>
                </WhatIsQwerteeModal>
              </li>
            </ul>
          </div>
        </div>
        <div className="block md:hidden pt-2">
          <button
            onClick={showMobileMenu}
            className="w-14 sm:w-16 p-3 mr-4 flex items-center rounded hover:bg-qw-tee-black"
          >
            <MenuIcon />
          </button>
        </div>
      </Container>
      <div
        id="mobile-menu"
        className={classNames('md:hidden pb-1 flex flex-col', {
          hidden: !showDropdown,
          block: !showDropdown,
        })}
      >
        <Link
          className={menuLinksClassesMobile}
          to={routeNames.home}
          onClick={showMobileMenu}
        >
          <Trans>Today's Tees</Trans>
        </Link>
        <Link
          className={menuLinksClassesMobile}
          to={routeNames.shopTees}
          onClick={showMobileMenu}
        >
          <Trans>Tee Shop</Trans>
        </Link>
        <Link
          className={menuLinksClassesMobile}
          to={routeNames.shopStickers}
          onClick={showMobileMenu}
        >
          <Trans>
            Stickers <sub className="text-qw-yellow-200 text-xs">New</sub>
          </Trans>
        </Link>
        {sweaters && (
          <Link
            className={menuLinksClassesMobile}
            to={routeNames.shopSweaters}
            onClick={showMobileMenu}
          >
            <Trans>Sweaters</Trans>
          </Link>
        )}
        {!featureFlags?.hidePrintShop && (
          <Link
            className={menuLinksClassesMobile}
            to={routeNames.shopPrints}
            onClick={showMobileMenu}
          >
            {featureFlags.framedPrints ? (
              <>
                <Trans>Framed Print Shop</Trans>
                <New />
              </>
            ) : (
              <Trans>Print Shop</Trans>
            )}
          </Link>
        )}
        <Link
          className={menuLinksClassesMobile}
          to={routeNames.designsVote}
          onClick={showMobileMenu}
        >
          <Trans>Vote</Trans>
        </Link>
        <Link
          className={menuLinksClassesMobile}
          to={routeNames.freeTees}
          onClick={showMobileMenu}
        >
          <Trans>Win/Free</Trans>
        </Link>
        <Link
          className={menuLinksClassesMobile}
          to={routeNames.help}
          onClick={showMobileMenu}
        >
          <Trans>Help</Trans>
        </Link>
        <Link
          className={menuLinksClassesMobile}
          to={routeNames.helpContact}
          onClick={showMobileMenu}
        >
          <Trans>Contact Us</Trans>
        </Link>
      </div>
    </div>
  );
}
