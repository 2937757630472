import { Trans } from '@lingui/macro';
import { Button } from './Button';
import { useConsent } from 'hooks/useConsent';
import routeNames from 'router/routeNames.json';
import classNames from 'classnames';

export function CookieConsent() {
  const consent = useConsent('cookieConsent');

  return (
    <div
      className={classNames(
        'flex items-center justify-center fixed z-50 inset-x-0 bottom-0 bg-qw-yellow-100 h-10 ',
        {
          'opacity-0 transition-opacity duration-700 hidden': !consent.show,
        }
      )}
    >
      <p className="text-xs lg:text-[0.8125rem] font-bold text-qw-gray-700 ">
        <Trans>
          Qwertee uses cookies. By using this site you agree to our use of
          cookies as described in our{' '}
          <a
            href={`${routeNames.cookies}`}
            className="underline hover:no-underline "
          >
            Cookie Policy
          </a>
        </Trans>
      </p>
      <Button
        className="h-7 min-w-[5rem] text-xs lg:text-[0.8125rem] font-bold !px-2 ml-1 hover:bg-black transition-colors duration-500"
        onClick={consent.handleConsent}
      >
        <Trans>Hide this</Trans>
      </Button>
    </div>
  );
}
