import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useUserAuth } from 'api/user/useUserAuth';
import { getCookie } from 'helpers/cookies';
import { useUserInfo } from 'api/user/useUserInfo';
import { useLocation } from 'react-router-dom';
import { useAddress } from 'api/checkout/useAddress';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!getCookie('loggedin'));
  const [user, setUser] = useState();
  const [address, setAddress] = useState();
  const { data } = useUserInfo({ skip: !isLoggedIn || !!user });
  const { data: addressData } = useAddress(!isLoggedIn || !!address);
  const location = useLocation();
  const { login, logout } = useUserAuth();

  const loginHandler = useCallback(
    async (values) => {
      const user = await login(values);
      setUser(user);
      if (window.intercomSettings) {
        window.intercomSettings.user_hash = user.hash;
        window.intercomSettings.user_id = user.id;
      }
      setIsLoggedIn(!!getCookie('loggedin'));
    },
    [login]
  );

  const logoutHandler = useCallback(async () => {
    await logout();
    setUser();
    setIsLoggedIn(false);
    document.cookie = `alias=;path=/;max-age=0;`;
  }, [logout]);

  const setUserEmail = useCallback(
    (email) => {
      setUser({ ...user, email });
    },
    [user]
  );

  const contextValue = useMemo(
    () => ({
      isLoggedIn,
      user,
      address,
      login: loginHandler,
      logout: logoutHandler,
      setUserEmail,
    }),
    [isLoggedIn, loginHandler, logoutHandler, setUserEmail, user, address]
  );

  useEffect(() => {
    data && setUser(data);
  }, [data]);

  useEffect(() => {
    !!addressData && setAddress(addressData);
  }, [addressData]);

  useEffect(() => {
    setIsLoggedIn(!!getCookie('loggedin'));
  }, [location]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
