import { useCallback, useEffect, useMemo } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { useCurrency } from 'hooks/useCurrency';
import { getDesignsImageUrl } from 'helpers/imageUrl';
import { getCookie } from '../../helpers/cookies';

const analytics = AnalyticsBrowser.load({
  writeKey: process.env.REACT_APP_SEGMENT_KEY,
});

function getOptions(user, address) {
  const traits = {};
  if (!!user) {
    traits.userId = user && user.id;
    traits.email = user && user.email;
    traits.user_name = user && user.username;
  }
  if (!!address) {
    traits.name = address && address.addressee;
    traits.phone = address && address.phone_number;
    traits.address = {
      city: address && address.city,
      state: address && address.state,
      country: address && address.country,
      postalCode: address && address.postal_code,
    };
  }
  const options = { context: { traits } };
  const integrations = getIntegrations(user?.hash);
  integrations && (options.integrations = integrations.integrations);
  return options;
}

function getProperties(properties) {
  properties = properties || {};
  properties.fbc = getCookie('_fbc');
  properties.fbp = getCookie('_fbp');
  return properties;
}

export const useSegment = () => {
  const { pathname } = useLocation();
  const currency = useCurrency();
  const { user, address } = useAuth();

  const track = useCallback(
    (event, properties) => {
      properties = getProperties(properties);
      const options = getOptions(user, address);
      analytics.track(event, properties, options);
    },
    [address, user]
  );

  const visitReturnAction = useCallback(() => {
    track('Return Form Opened', {});
  }, [track]);

  const productViewAction = useCallback(
    (product) => {
      track('Product Viewed', {
        product_id: product.id.toString(),
        name: product.name,
      });
    },
    [track]
  );

  const addToCartAction = useCallback(
    (product) => {
      track('Product Added', {
        product_id: product.productId.toString(),
        category: product.category,
        name: product.name,
        price: Number(product.price[currency.code]),
        variant: product.variant,
        quantity: Number(product.quantity),
        currency: currency.code,
      });
    },
    [currency.code, track]
  );

  const cartViewedAction = useCallback(
    (items) => {
      track('Cart Viewed', {
        products: mapItemsHelper(items, currency.code),
        currency: currency.code,
      });
    },
    [currency.code, track]
  );

  const identifyOrderAction = useCallback(
    (order) => {
      const properties = {
        email: order.email,
        name: order.addressee,
        phone: order.phone_number,
        address: {
          city: order.city,
          state: order.state,
          country: order.country,
          postalCode: order.postal_code,
        },
      };
      const parameters = getIntegrations(user?.hash || order.userHash);
      return analytics.identify(user?.id, properties, parameters);
    },
    [user]
  );

  const orderCompleteAction = useCallback(
    (order) => {
      identifyOrderAction(order);
      track('Order Completed', {
        order_id: order.id.toString(),
        total: order.total,
        tax: Number(order.tax),
        currency_total: order.total,
        subtotal: Number(order.sub_total),
        shipping: Number(order.shipping_cost),
        coupon: order.discount_code,
        currency: currency.code,
        products: order.items.map((item) => ({
          product_id: item.product_id.toString(),
          name: item.name,
          price: item.unit_price,
          quantity: item.quantity,
          category: item.sizeFull && item.sizeFull.split(' ')[0],
        })),
      });
    },
    [currency.code, identifyOrderAction, track]
  );

  const identify = useCallback(() => {
    if (!user?.id) return;
    const traits = {
      email: user.email,
      userName: user.username,
      userId: user.id,
    };
    if (address) {
      traits.name = address.addressee;
      traits.phone = address.phone_number;
      traits.address = {
        city: address.city,
        state: address.state,
        country: address.country,
        postalCode: address.postal_code,
      };
    }
    const options = getIntegrations(user.hash);
    return analytics.identify(user.id, traits, options);
  }, [user, address]);

  const getAnalytics = useCallback(() => {
    return analytics;
  }, []);

  useEffect(() => {
    const properties = getProperties();
    const options = getOptions(user, address);
    analytics.page('', '', properties, options);
  }, [address, pathname, user]);

  const chatClickAction = useCallback(
    () => analytics.track('Chat clicked'),
    []
  );

  useEffect(() => {
    identify();
  }, [identify, user]);

  return useMemo(
    () => ({
      cartViewedAction,
      productViewAction,
      addToCartAction,
      orderCompleteAction,
      getAnalytics,
      visitReturnAction,
      chatClickAction,
    }),
    [
      cartViewedAction,
      productViewAction,
      addToCartAction,
      orderCompleteAction,
      getAnalytics,
      visitReturnAction,
      chatClickAction,
    ]
  );
};

function mapItemsHelper(items) {
  return items.map((item) => {
    return {
      image_url: getDesignsImageUrl(item.img, 450, 540),
      name: item.name,
      price: Number(item.price),
      product_id: item.id.toString(),
      quantity: item.num,
      size: item.size,
    };
  });
}

function getIntegrations(hash) {
  if (!hash) return;
  return { integrations: { Intercom: { user_hash: hash } } };
}
